<template>
  <div
    id="menuboek-container"
    :style="{ color: config.color, backgroundColor: config.backgroundColor }"
  >
    <div v-if="config.logoId !== 0" class="logo-container">
      <img :src="api_url + 'image/' + config.logoId" />
    </div>
    <div class="header" v-else>
      <div class="brand-icon">
        <BaseBrandIcon />
      </div>
      <h2>{{ menubookName }}</h2>
    </div>
    <div class="menuboek">
      <BaseMenuboekItem
        v-for="(item, index) in menubook"
        :key="index"
        :visible="item.visible"
        :prijs="item.price"
        :type="item.category"
        :item="item.name"
        :show="item.show"
        :index="index"
        :description="item.description"
        @menuboekItemPressed="onMenuboekItemPressed"
      />
    </div>
    <footer
      :style="{ color: config.backgroundColor, backgroundColor: config.color }"
    >
      Powered by The Menu BV
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseMenuboekItem from "@/components/BaseMenuboekItem.vue";
import BaseBrandIcon from "@/components/BaseBrandIcon.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
const { api_url } = require("../../config/url");

export default {
  name: "MenuboekViewer",
  data() {
    return {
      api_url: api_url,
    };
  },
  components: {
    BaseMenuboekItem,
    BaseBrandIcon,
  },
  computed: {
    ...mapGetters({
      menubook: "menuboek/menuboek",
      config: "menuboek/config",
      menubookName: "menuboek/menubookName",
    }),
  },
  methods: {
    ...mapActions({
      fetchMenuboekItemsByHorecaId: "menuboek/fetchMenuboekItemsByHorecaId",
    }),
    ...mapMutations({
      toggleMenuboekChapter: "menuboek/toggleMenuboekChapter",
    }),
    onMenuboekItemPressed(index) {
      this.toggleMenuboekChapter(index);
    },
  },
  created() {
    this.fetchMenuboekItemsByHorecaId(this.$route.params.horecaId);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

#menuboek-container {
  .header {
    display: grid;
    grid-template-rows: 70px auto;

    .brand-icon {
      background-color: $lm-brand-color;
      margin-left: $standard-margin;
      margin-top: 12px;
      padding: 5px;
      width: 44px;
      height: 44px;
      border-radius: $standard-radius;
    }

    h2 {
      text-align: center;
      padding-top: calc(#{$standard-padding} * 1);
      margin-bottom: $standard-margin;
      font-family: $brand-font-family;
      font-size: 1.9rem;
      font-weight: 800;
    }
  }

  .logo-container {
    width: 100vw;
    height: 100px;
    padding: $standard-padding;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  footer {
    padding: $standard-padding;
    font-weight: 600;
  }
}
.menuboek {
  div:last-child {
    padding-bottom: calc(#{$standard-padding}* 2);
  }
}
</style>
