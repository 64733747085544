<template>
  <div v-if="show && visible" :class="['container-menuboek-item', type]">
    <div
      v-if="type === 'hoofdtype'"
      v-on:click="$emit('menuboekItemPressed', index)"
    >
      {{ item }}
    </div>
    <div v-else-if="type === 'subtype'">
      <span
        >{{ item }}
        <span v-if="description.length > 0" v-on:click="openDescription"
          ><font-awesome-icon :icon="['fas', 'info-circle']" /></span
      ></span>
    </div>
    <div v-else class="content">
      <span
        >{{ item }}
        <span v-if="description.length > 0" v-on:click="openDescription"
          ><font-awesome-icon :icon="['fas', 'info-circle']" /></span
      ></span>
      <span>&euro;</span>
      <span class="prijs">{{ Number(prijs).toFixed(2) }}</span>
    </div>
    <div v-if="modalOpen"  class="modal">
      <div @click="closeDescription" class="close-button">
        <font-awesome-icon :icon="['fas', 'times']" />
      </div>
      <div class="modal-content">
        <h3>{{ item }}</h3>
        <p>{{ description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseMenuboekItem",
  data() {
    return {
      modalOpen: false,
    };
  },
  props: {
    type: {
      type: String,
    },
    item: {
      type: String,
    },
    show: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
    },
    prijs: {
      type: Number,
    },
    description: {
      type: String,
      default: "",
    },
  },
  emits: ["menuboekItemPressed"],
  methods: {
    openDescription() {
      this.modalOpen = true;
    },
    closeDescription() {
      this.modalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.container-menuboek-item {
  padding-left: $standard-padding;
  padding-right: $standard-padding;
}

.hoofdtype {
  font-size: 1.5rem;
  padding-top: $standard-padding;
  padding-bottom: $standard-padding;
  font-weight: bold;
  border-top: 0.5px solid $lm-divider-color;
  border-top-left-radius: $standard-radius;
  border-top-right-radius: $standard-radius;
  cursor: pointer;
}

.subtype {
  font-weight: 600;
  padding-top: calc(#{$standard-padding} * 1.5);
}

.hoofdtype + .subtype {
  padding-top: 0;
}

.hoofdtype + .item {
  padding-top: $standard-padding;
}

.subtype + .hoofdtype {
  margin-top: $standard-margin;
}

.item + .hoofdtype {
  margin-top: $standard-margin;
}

.item {
  padding-top: calc(#{$standard-padding}/ 2);
  .content {
    display: grid;
    grid-template-columns: auto 15px 55px;

    .prijs {
      text-align: right;
    }

    span:first-child {
      padding-right: $standard-padding;
    }
  }
}

.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 5vw;
  top: 10vh;
  width: 89vw; /* Full width */
  min-height: 30vh; /* Full height */
  max-height: 80vh; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  backdrop-filter: blur(0.8rem);
  border: gray solid 0.5px;
  border-radius: 10px;
  overflow: scroll;

  .close-button {
    padding: $standard-padding;
    padding-bottom: 0;
    font-size: 1.8rem;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    display: flex;
    justify-content: flex-end;
  }
  /* Modal Content/Box */
  .modal-content {
    padding: $standard-padding;
    width: 75%;
    margin: auto;
    font-weight: 400;

    h3 {
      margin-bottom: $standard-margin
    }
  }
}
</style>
